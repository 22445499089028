import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

//รายการยาหรืออุปกรณ์ในชุด
const getDrugSetList = async (body) => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drugset/list',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// ข้อมูลรายการชุดยาหรืออุปกรณ์
const getDrugSetById = async (id) => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'drugset',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// เพิ่มข้อมูลรายการชุดยาหรืออุปกรณ์
const drugSetAdd = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'drugset',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// แก้ไขข้อมูลรายการชุดยาหรืออุปกรณ์
const drugSetUpdate = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugset',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// แก้ไขสถานะข้อมูลรายการชุดยาหรืออุปกรณ์
const drugSetUpdateStatus = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugset/status',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//รายการยาหรืออุปกรณ์ในชุด
const detailDrugSetList = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'drugset/druglist',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//เพิ่มข้อมูลยาหรืออุปกรณ์ในชุด
const detailDrugSetAdd = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'drugset/drug',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//แก้ไขข้อมูลยาหรืออุปกรณ์ในชุด
const detailDrugSetUpdate = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugset/drug',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//ลบข้อมูลยาหรืออุปกรณ์ในชุด
const detailDrugSetRemove = async (id) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'drugset/drug',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//แก้ไขสถานะข้อมูลยาหรืออุปกรณ์ในชุด
const detailDrugSetUpdateStatus = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugset/drugstatus',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}


export default {
  getDrugSetList,
  getDrugSetById,
  drugSetAdd,
  drugSetUpdate,
  drugSetUpdateStatus,
  detailDrugSetList,
  detailDrugSetAdd,
  detailDrugSetUpdate,
  detailDrugSetRemove,
  detailDrugSetUpdateStatus,
}
